.innerpagesSec {
   background: url('../../images/innerPagesBg.png') no-repeat top -404px center;

   .innerPagesCnt {
      width: 100%;
      min-height: calc(100vh - 133px);
      padding: 30px 15px;

      h3 {
         &.secHeading {
            font-size: clamp(20px, 2.50vw, 30px);
            font-weight: 900;
            font-family: var(--font-druk);
            background: -webkit-linear-gradient(-85deg, rgba(71, 71, 71, 1) 0%, rgba(255, 255, 255, 1) 50%, rgba(158, 158, 158, 1) 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            margin-bottom: 35px;
         }

         &.secMdHeading {
            font-size: clamp(15px, 1.50vw, 18px);
            color: #ffffff;
            margin-bottom: 15px;
         }
      }

      p {
         font-size: 14px;
         font-weight: 300;
      }

      .card {
         background: rgb(71, 71, 71);
         background: linear-gradient(-89deg, rgba(71, 71, 71, 1) 0%, rgba(15, 14, 24, 1) 51%, rgba(71, 71, 71, 1) 100%);
         position: relative;
         border-radius: 20px;
         padding: 25px;
         margin-bottom: 25px;

         &:before {
            content: '';
            position: absolute;
            left: 1px;
            top: 1px;
            width: calc(100% - 2px);
            height: calc(100% - 2px);
            background: #0C0C0C;
            border-radius: 20px;
            z-index: 1;
         }

         &.tableCard {
            padding: 20px 15px;

            table {
               margin-bottom: 0px;

               &.table-borderless {
                  thead {
                     tr {
                        th {
                           font-size: 14px;
                           color: var(--white-06);
                           border-top: 0px;
                           border-bottom: 1px solid rgba(255, 255, 255, 0.1);
                           font-weight: 300;
                           padding-top: 0px;

                           &:first-child {
                              padding-left: 0px;
                           }

                           &:last-child {
                              padding-right: 0px;
                           }
                        }
                     }
                  }

                  tbody {
                     tr {
                        td {
                           font-size: 14px;
                           color: #ffffff;
                           border-top: 0px;
                           font-weight: 300;
                           padding-bottom: 0px;

                           &:first-child {
                              padding-left: 0px;
                           }

                           &:last-child {
                              padding-right: 0px;
                           }
                        }
                     }
                  }
               }

               thead {
                  tr {
                     th {
                        font-size: 14px;
                        color: var(--white-06);
                        border-top: 0px;
                        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
                        font-weight: 300;
                        padding-top: 0px;

                        &:first-child {
                           padding-left: 0px;
                        }

                        &:last-child {
                           padding-right: 0px;
                        }
                     }
                  }
               }

               tbody {
                  tr {
                     td {
                        font-size: 14px;
                        color: #ffffff;
                        font-weight: 300;
                        border-color: rgba(255, 255, 255, 0.1);

                        &:first-child {
                           padding-left: 0px;
                        }

                        &:last-child {
                           padding-right: 0px;
                        }
                     }

                     &:last-child {
                        td {
                           padding-bottom: 0px;
                        }
                     }
                  }
               }
            }
         }

         &.walletCard {
            min-height: 425px;
         }

         &.notesCard {
            min-height: 128px;

            p {
               line-height: 24px;
            }

            .cardInnerCnt{
               z-index: 1;
            }
         }

         &.blackCard {
            border-radius: 10px;

            &::before {
               content: '';
               width: calc(100% - 2px);
               height: calc(100% - 2px);
               background-color: #060609;
               position: absolute;
               left: 1px;
               top: 1px;
               border-radius: 10px;
            }
         }

         .profileInfoCnt {
            border-right: 1px solid rgba(255, 255, 255, 0.1);

            .profileName {
               font-size: clamp(16px, 1.67vw, 20px);
               color: #ffffff;
               margin-bottom: 0px;
            }

            .profileMail {
               font-size: 14px;
               line-height: 24px;
               color: var(--white-06);
               margin-bottom: 0px;
               font-weight: 300;
            }
         }

         .idCnt {
            border-right: 1px solid rgba(255, 255, 255, 0.1);
            min-height: 98px;
         }

         .regDateCnt {
            border-right: 1px solid rgba(255, 255, 255, 0.1);
            min-height: 98px;
         }

         .dayCnt {
            min-height: 98px;
         }

         .cardInnerCnt {
            position: relative;
            width: 100%;
            z-index: 2;
         }

         .cardLink {
            font-size: 14px;
            line-height: 26px;
            color: #FFE388;
            text-decoration: underline;
         }

         .cardLabel {
            font-size: 14px;
            color: rgba(255, 255, 255, 0.5);
            line-height: 24px;
            font-weight: 300;
         }

         .cardLinkValue {
            font-size: 15px;
            color: rgba(255, 255, 255, 1);
            line-height: 24px;
            font-weight: 300;
         }

         .cardValue {
            font-size: 16px;
            color: rgba(255, 255, 255, 1);
            line-height: 24px;
         }

         .dateValue {
            font-size: clamp(16px, 1.83vw, 22px);
            color: rgba(255, 255, 255, 1);
            line-height: 24px;
         }

         .ftScl {
            ul {
               justify-content: flex-start;

               li {
                  a {
                     width: 35px;
                     height: 35px;
                  }
               }
            }
         }

         .cardHeader {
            padding-bottom: 15px;
            border-bottom: 1px solid rgba(255, 255, 255, 0.1);
            margin-bottom: 15px;

            h3 {
               font-size: clamp(15px, 1.50vw, 18px);
               color: #ffffff;
               margin-bottom: 0px;
            }

            .rightBorderedCnt {
               border-right: 1px solid rgba(255, 255, 255, 0.1);
            }
         }

         .profPicEdit {
            position: relative;
            width: 120px;
            height: 120px;

            img {
               &.profilePic {
                  width: 120px;
                  height: 120px;
                  border-radius: 50%;
                  border: 6px solid #212121;
               }
            }

            .editIcon {
               width: 39px;
               height: 39px;
               display: flex;
               justify-content: center;
               align-items: center;
               position: absolute;
               right: 0px;
               bottom: 0px;
               background: #2D2C2C;
               border-radius: 50%;

               &:hover {
                  cursor: pointer;
               }
            }
         }

         .editProfValueCnt {
            min-width: 200px;
            margin-right: 15px;
         }

         form {
            .form-control {
               border-top-left-radius: 5px;
               border-bottom-left-radius: 5px;
            }

            .input-group {
               .input-group-append {
                  margin-left: 0px;
               }
            }

            .dropdown {
               .dropdown-toggle {
                  width: 100%;
                  background: rgba(255, 255, 255, 0.05) url('../../images/ddIcon.png') no-repeat center right 10px;
                  border-color: rgba(255, 255, 255, 0.1);
                  font-size: 14px;
                  border-radius: 5px;
                  height: 45px;
                  text-align: left;

                  &:after {
                     display: none;
                  }
               }

               .dropdown-menu {
                  width: 100%;
                  background: rgba(47, 47, 47, 1);
                  border-color: rgba(255, 255, 255, 0.1);

                  a {
                     color: var(--white-06);

                     &:hover {
                        color: var(--ylwTxt);
                        background: rgba(255, 255, 255, 0.05);
                        cursor: pointer;
                     }
                  }

               }
            }
         }

         h3 {
            &.cardHeading {
               font-size: 14px;
               line-height: 24px;
               font-weight: 500;
               color: #ffffff;
            }
         }

         .goldCnt {
            background: rgb(197, 162, 82);
            background: linear-gradient(-74deg, rgba(197, 162, 82, 1) 0%, rgba(248, 220, 143, 1) 50%, rgba(197, 162, 82, 1) 100%);
            width: 100%;
            border-radius: 10px;
            padding: 18px;
            color: #000000;

            h3,
            h5 {
               color: #000000;
            }

            .cardLabel {
               line-height: 28px;
               font-weight: 500;
            }

            .cardValue {
               line-height: 24px;
               font-weight: 700;
            }
         }

         .coinInfoCnt {
            height: 408px;
            overflow-y: auto;

            &.unHgt {
               height: auto;
            }

            .coinInfoRow {
               width: 100%;
               padding: 10px 0px;
            }
         }

         .nav-tabs {
            padding-left: 0px;
            margin-bottom: 0px;
            border-bottom: 0px;

            li {
               display: inline-block;

               a {
                  font-size: 20px;
                  color: var(--white-06);
                  margin-right: 30px;
                  padding: 0px;
                  background: transparent;
                  border: 0px;

                  &.active {
                     color: #F8DC8F;
                     border: 0px;
                  }

                  &:hover {
                     cursor: pointer;
                  }
               }
            }
         }

         .bdrCnt {
            border-right: 1px solid rgba(255, 255, 255, 0.1);

            @media(max-width:991px) {
               border-right: 0px;
               border-bottom: 1px solid rgba(255, 255, 255, 0.1);
            }
         }

         .outlinedBtn {
            background: rgba(255, 255, 255, 0.05);
            height: 35px;
            font-size: 14px;
            color: #FFE388;
            padding: 7px 12px;
            border: 1px solid #FFE388;
            border-radius: 50px;
            transition: all 0.5s;

            &:hover {
               background: #FFE388;
               color: #000000;
               text-decoration: none;
            }
         }

         &.goldcard {
            &:before {
               background-image: linear-gradient(97.53deg, #C5A252 6.3%, #F8DC8F 53.15%, #C5A252 100%);
            }

            .cardLabel,
            .cardValue {
               color: #000000;
            }
         }
      }

      .paginationCnt {
         display: flex;
         justify-content: flex-end;

         .pagination {
            background: #0C0C0C;
            border: 1px solid rgba(255, 255, 255, 0.1);
            padding: 5px 8px;
            border-radius: 25px;

            li {

               button,
               a {
                  padding: 0px 10px;
                  font-size: 14px;
                  line-height: 24px;
                  color: #fff;

                  &:hover,
                  &.active {
                     color: #FFE388;
                     box-shadow: none;
                  }
               }

               &.prev {

                  button,
                  a {
                     border-right: 1px solid rgba(255, 255, 255, 0.1);
                  }

               }

               &.next {

                  button,
                  a {
                     border-left: 1px solid rgba(255, 255, 255, 0.1);
                  }

                  img {
                     transform: rotate(180deg);
                  }
               }
            }
         }
      }

      .profileInfoCnt {
         width: 33%;

         @media(max-width:991px) {
            width: 100%;
            border-right: 0px !important;
            border-bottom: 1px solid rgba(255, 255, 255, 0.1);
            padding-bottom: 15px;
         }
      }

      .idCnt {
         width: 22%;

         @media(max-width:991px) {
            width: 33.33%;
         }

         @media(max-width:767px) {
            width: 100%;
            border-right: 0px !important;
            border-bottom: 1px solid rgba(255, 255, 255, 0.1);
         }
      }

      .regDateCnt {
         width: 25%;

         @media(max-width:991px) {
            width: 33.33%;
         }

         @media(max-width:767px) {
            width: 100%;
            border-right: 0px !important;
            border-bottom: 1px solid rgba(255, 255, 255, 0.1);
         }
      }

      .dayCnt {
         width: 20%;

         @media(max-width:991px) {
            width: 33.33%;
         }

         @media(max-width:767px) {
            width: 100%;
            border-right: 0px !important;
         }
      }

      .cardTitle {
         font-size: clamp(16px, 1.50vw, 18px);
         line-height: 24px;
         color: #ffffff;
         margin-bottom: 15px;

         &.lgTitle {
            font-size: clamp(16px, 1.50vw, 20px);
         }
      }

      form {
         .uplCard {
            width: 272px;
            height: 222px;
            border-radius: 20px;
            padding: 15px;
            background: #0C0C0C;
            border: 1px solid #474747;

            &.lgUpl {
               width: 100%;
            }

            .uplCnt {
               position: relative;
               width: 100%;
               height: 100%;
               border: 1px dashed #474747;
               border-radius: 20px;
               z-index: 1;
               display: flex;
               justify-content: center;
               align-items: center;

               label {
                  position: absolute;
                  width: 100%;
                  height: 100%;
                  left: 0px;
                  top: 0px;
                  z-index: 2;
               }

               input[type="file"] {
                  position: absolute;
                  width: 100%;
                  height: 100%;
                  left: 0px;
                  top: 0px;
                  z-index: 3;
                  opacity: 0;
               }
            }
         }
      }

      hr {
         border-color: rgba(255, 255, 255, 0.1);
      }

      ol {
         li {
            font-size: 14px;
            font-weight: 300;
            line-height: 31px;
         }
      }

      .alert {
         border-radius: 8px;
         padding: 2px 0px;
         width: 100%;
         max-width: 760px;
         margin: 20px auto;

         &.alert-danger {
            background: rgba(241, 71, 87, 0.4);
            border-color: #AB4E58;
         }

         h3 {
            font-size: 16px;
            color: #ffffff;
            line-height: 24px;
            margin-bottom: 0px;
         }

         p {
            font-size: 14px;
            font-weight: 300;
            color: #ffffff;
            line-height: 24px;
            margin-bottom: 0px;
         }

         .close {
            color: #ffffff;
         }
      }

      .filterCnt {
         .form-control {
            background: #0C0C0C;
            border-color: #474747;
            font-size: 14px;
            border-radius: 5px;
            height: 45px;
            margin: 0px 8px;
            color: #fff;

            &.searchbox {
               background: url('../../images/searchIcon.png') no-repeat center right 10px;
            }
         }

         .filterBtn {
            min-width: 136px;
            height: 45px;
            background: #0C0C0C;
            border-color: #474747;
            font-size: 14px;
            border-radius: 5px;
            margin: 0px 8px;
            color: #fff;

            &:hover {
               background: #FFE389;
               color: #000;

               img {
                  filter: brightness(0);
               }
            }
         }

         .filtValueCnt {
            white-space: nowrap;
            padding-right: 20px;
            margin-right: 20px;
            border-right: 1px solid rgba(255, 255, 255, 0.1);
         }

         input {
            &[type="date"] {
               background: rgba(255, 255, 255, 0.05) url('../../images/Auth/calendarIcon.png') no-repeat center right 10px;
            }

            &[type="date"]::-webkit-inner-spin-button {
               display: none;
            }

            &[type="date"]::-webkit-calendar-picker-indicator {
               opacity: 0;
            }
         }
      }

      .pgLink {
         font-size: 18px;
      }

      .stakeViewPhaseCnt {
         width: 150px;
         height: 120px;
         position: relative;
         background: rgb(71, 71, 71);
         background: linear-gradient(-89deg, rgba(71, 71, 71, 1) 0%, rgba(15, 14, 24, 1) 51%, rgba(71, 71, 71, 1) 100%);
         border-radius: 20px;
         margin-right: 8px;
         margin-bottom: 10px;

         &:before {
            content: '';
            position: absolute;
            width: calc(100% - 2px);
            height: calc(100% - 2px);
            background: #060609;
            border-radius: 20px;
            left: 1px;
            top: 1px;
         }

         .phaseNoCnt {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            position: relative;
            background: rgb(197, 162, 82);
            background: linear-gradient(-74deg, rgba(197, 162, 82, 1) 0%, rgba(248, 220, 143, 1) 50%, rgba(197, 162, 82, 1) 100%);
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 8px;

            &:before {
               content: '';
               position: absolute;
               left: 1px;
               top: 1px;
               width: calc(100% - 2px);
               height: calc(100% - 2px);
               border-radius: 50%;
               background: #060609;
               z-index: 2;
            }

            span {
               position: relative;
               z-index: 3;
               color: #ffffff;
               font-size: 14px;
               font-weight: 400;
            }
         }

         .phaseContCnt {
            position: relative;
            z-index: 3;
            width: 100%;
            padding: 15px;

            img {
               &.logoIcon {
                  display: none;
               }
            }
         }

         h3 {
            color: #ffffff;
            font-size: 14px;
            font-weight: 400;
            margin-bottom: 0px;
         }

         &.unlock {
            &:before {
               background: #181818;
            }

            .phaseContCnt {
               img {
                  &.lockIcon {
                     display: none;
                  }

                  &.logoIcon {
                     display: block;
                     animation: coinflip 5s linear 2s infinite alternate;
                  }
               }

               .coinCount {
                  font-size: clamp(16px, 1.5vw, 20px);
                  font-weight: 500;
               }
            }
         }

         &.totClaimCnt {
            width: calc(100% - 948px);
            margin-right: 0px;

            @media(max-width:1199px) {
               min-width: 250px;
               width: auto;
            }
         }
      }

      .stakeViewRow {
         width: 100%;
         position: relative;
         background: rgb(71, 71, 71);
         background: linear-gradient(-89deg, rgba(71, 71, 71, 1) 0%, rgba(15, 14, 24, 1) 51%, rgba(71, 71, 71, 1) 100%);
         border-radius: 10px;
         padding: 20px;
         margin-bottom: 15px;

         &:before {
            content: '';
            position: absolute;
            width: calc(100% - 2px);
            height: calc(100% - 2px);
            background: #0c0c0c;
            border-radius: 10px;
            left: 1px;
            top: 1px;
            z-index: 2;
         }

         .stakeViewInnerCnt {
            position: relative;
            z-index: 3;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            flex-wrap: wrap;

            .stakeValueCnt {
               width: 12.5%;
               padding-right: 15px;
               display: flex;
               flex-direction: column;

               @media(max-width:1199px) {
                  width: 20%;
               }

               @media(max-width:991px) {
                  width: 33.33%;
               }

               @media(max-width:767px) {
                  width: 50%;
               }

               @media(max-width:575px) {
                  width: 100%;
                  flex-direction: row;
                  justify-content: space-between;
               }
            }

            .toggleCnt {
               margin-top: 20px;
               padding: 20px 0px;
               border-top: 1px solid rgba(255, 255, 255, 0.05);
            }
         }

         .form-group {
            .input-group-append {
               margin-left: 0px;
            }

            input {
               height: 60px;
               border-top-left-radius: 20px;
               border-bottom-left-radius: 20px;
            }

            .input-group-text {
               border-top-right-radius: 20px;
               border-bottom-right-radius: 20px;
            }
         }
      }

      .nav-tabs {
         border-bottom: 0px;

         .nav-item {
            .nav-link {
               font-size: 16px;
               color: var(--white-06);
               font-weight: 300;
               padding: 13px 20px;
               padding-top: 0px;
               border: 0px;
               border-bottom: 1px solid rgba(255, 255, 255, 0.1);
               margin-bottom: 0px;
               transition: all 0.5s;

               &.active,
               &:hover {
                  background: transparent;
                  border: 0px;
                  color: #F8DC8F;
                  border-bottom: 1px solid #F8DC8F;
               }

               &:hover {
                  cursor: pointer;
               }
            }

            &:first-child {
               .nav-link {
                  padding-left: 0px;
               }
            }

            &:last-child {
               .nav-link {
                  padding-right: 0px;
               }
            }
         }
      }

      &.cmsCnt {
         .aboutSec {
            background: url('../../images/cms/aboutBg.png') no-repeat right top;
            background-size: 50%;
            padding: 85px 0px;

            @media(max-width:991px) {
               background-position: bottom right;
               background-size: 100%;
               padding: 0px;
               padding-bottom: 25px;
            }
         }

         p {
            font-size: clamp(14px, 1.61vw, 16px);
            color: var(--white-06);
            font-weight: 200;
            line-height: 28px;
            margin-bottom: 10px;
            text-transform: capitalize;
         }

         h3.secHeading {
            @media(max-width:991px) {
               margin-bottom: 20px;
            }
         }

         h3.cmsHeading {
            font-size: clamp(16px, 1.67vw, 20px);
            font-weight: 400;
            color: #ffffff;
            margin-bottom: 10px;
         }

         .faqRow {
            width: 100%;
            padding-bottom: 15px;
            margin-bottom: 25px;
            border-bottom: 1px solid rgba(255, 255, 255, 0.1);

            h3.cmsHeading {
               margin-bottom: 15px;
            }
         }
      }

      &.notFdCnt {
         min-height: 100vh;
         display: flex;
         justify-content: center;
         align-items: center;
      }

      .BisTorsec {
         &.cmntxt {
            h3 {
               color: #FFFFFF;
               font-family: 'Sora', sans-serif;
               font-size: 20px;
               font-weight: 700;
               margin-bottom: 0;

               &.text-yellow {
                  color: #f7dc8f;
                  font-weight: 400;
               }
            }

            h4 {
               color: #ffffff;
               font-family: 'Sora', sans-serif;
               font-size: 20px;
               font-weight: 700;
               text-transform: uppercase;
               margin-bottom: 0;

               &.text-blk {
                  color: #000000;
                  text-transform: unset;
               }

               span {
                  font-size: 14px;
               }
            }

            h5 {
               color: #ffffff;
               font-family: 'Sora', sans-serif;
               font-size: 14px;
               font-weight: 400;
               margin-bottom: 0;

               &.text-blk {
                  color: #000000;
               }

               &.text-yellow {
                  color: #f7dc8f;
                  text-decoration: underline;
                  text-decoration-color: #f7dc8f;
               }

               &.fn16 {
                  font-size: 16px;
               }

               &.fn17 {
                  font-size: 17px;
               }
            }

            h6 {
               color: #FFFFFF;
               font-family: 'Sora', sans-serif;
               font-size: 14px;
               font-weight: 400;
               line-height: 22px;

               span {

                  &.ashtxt {
                     opacity: 50%;
                  }
               }
            }

            .mt20 {
               margin-top: 20px;
            }

            .mt26 {
               margin-top: 26px;
            }

            .mt41 {
               margin-top: 41px;
            }

            .claimbtn {
               min-width: 122px;
               min-height: 46px;
               border-radius: 50px;
               background: linear-gradient(97.53deg, #c5a252 0%, #f7dc8f 50%, #c5a252 100%);
               color: #000000;
               font-family: 'Sora', sans-serif;
               font-size: 14px;
               font-weight: 600;

               &:hover {
                  background: linear-gradient(97.53deg, #f7dc8f 0%, #c5a252 50%, #f7dc8f 100%);

               }
            }
         }

         .borderbtm {
            border-bottom: 1px solid rgba(255, 255, 255, 10%);
            padding-bottom: 20px;
         }

         .hrline {
            position: relative;

            &::after {
               position: absolute;
               content: '';
               width: 1px;
               height: 33px;
               background: rgba(255, 255, 255, 10%);
               left: -27px;
               top: 2px;
            }

            &::before {
               position: absolute;
               content: '';
               width: 1px;
               height: 33px;
               background: rgba(255, 255, 255, 10%);
               right: 15px;
               top: 2px;
            }
         }

         .ashBg {
            background: rgb(71, 71, 71);
            background: linear-gradient(97.53deg, rgba(71, 71, 71, 1) 0%, rgba(15, 14, 24, 1) 51%, rgba(71, 71, 71, 1) 100%);
            border-radius: 20px;
            background-size: 100% 100%;
            padding: 22px 27px;
            position: relative;

            &::before {
               position: absolute;
               content: "";
               background: #060609;
               width: calc(100% - 2px);
               height: calc(100% - 2px);
               top: 1px;
               border-radius: 20px;
               left: 1px;
               z-index: 2;
            }

            .inrCnt {
               position: relative;
               z-index: 3;
            }

            .yellBG {
               border-radius: 10px;
               background: linear-gradient(97.53deg, #c5a252 0%, #f7dc8f 50%, #c5a252 100%);
               min-height: 82px;
               padding: 14px 24px;
            }

            .ash1 {
               background: linear-gradient(97.53deg, #474747 0%, #0F0E18 50%, #474747 100%);
               border-radius: 20px;
               min-height: 82px;
               padding: 18px;
               position: relative;

               &::before {
                  position: absolute;
                  content: "";
                  background: #060609;
                  width: calc(100% - 2px);
                  height: calc(100% - 2px);
                  top: 1px;
                  border-radius: 20px;
                  left: 1px;
                  z-index: 2;
               }

               .inrCnt {
                  position: relative;
                  z-index: 333;
               }

               ul {
                  li {
                     margin-top: 10px;

                     a {
                        color: rgba(255, 255, 255, 0.2);
                        font-family: 'Sora', sans-serif;
                        font-size: 16px;
                        font-weight: 600;
                        padding: 0;

                        .crown1img {
                           display: none;
                        }

                        .crown2img {
                           display: block;
                           opacity: 0.2;
                        }

                        &.active {
                           color: #ffe389;
                           padding: 0;

                           .crown1img {
                              display: block;
                           }

                           .crown2img {
                              display: none;
                              opacity: 0.2;
                           }
                        }
                     }
                  }
               }
            }

            .ash2 {
               background: linear-gradient(97.53deg, #474747 0%, #0F0E18 50%, #474747 100%);
               border-radius: 20px;
               min-height: 132px;
               padding: 18px;
               position: relative;

               &::before {
                  position: absolute;
                  content: "";
                  background: #060609;
                  width: calc(100% - 2px);
                  height: 98%;
                  height: 136px;
                  top: 1px;
                  border-radius: 20px;
                  left: 1px;
                  z-index: 2;
               }

               .inrCnt {
                  position: relative;
                  z-index: 333;
               }

               .levelbg {
                  background: url('../../images/level.png') no-repeat;
                  background-size: contain;
                  height: 30px;
                  width: 30px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  color: #ffff;
                  font-size: 14px;
                  font-weight: 400;
                  font-family: 'Sora', sans-serif;
               }
            }

            .ash3 {
               background: rgb(71, 71, 71);
               background: linear-gradient(97.53deg, rgba(71, 71, 71, 1) 0%, rgba(15, 14, 24, 1) 51%, rgba(71, 71, 71, 1) 100%);
               border-radius: 20px;
               background-size: 100% 100%;
               min-height: 119px;
               padding: 19px;
               position: relative;

               &:before {
                  position: absolute;
                  content: "";
                  background: #060609;
                  width: calc(100% - 2px);
                  height: calc(100% - 2px);
                  top: 1px;
                  border-radius: 20px;
                  left: 1px;
                  z-index: 2;
               }

               .inrCnt {
                  position: relative;
                  z-index: 3;
               }
            }

            .tmbg {
               background: rgb(218, 218, 243);
               background: linear-gradient(-64deg, rgba(218, 218, 243, 1) 0%, rgba(15, 14, 24, 1) 51%, rgba(218, 218, 243, 1) 100%);
               min-height: 128px;
               padding: 18px;
               border-radius: 20px;
               position: relative;
               margin: 5px 0px;

               &.mn119 {
                  min-height: 119px;
               }

               &.purpleBg {
                  &:before {
                     content: '';
                     position: absolute;
                     background: rgb(105, 105, 122);
                     background: linear-gradient(180deg, rgba(105, 105, 122, 1) 0%, rgba(56, 56, 64, 1) 100%);
                     left: 1px;
                     top: 1px;
                     width: calc(100% - 2px);
                     height: calc(100% - 2px);
                     border-radius: 20px;
                     z-index: 1;
                  }
               }

               &.blueBg {
                  &:before {
                     content: '';
                     position: absolute;
                     background: rgb(84, 84, 108);
                     background: linear-gradient(180deg, rgba(84, 84, 108, 1) 0%, rgba(34, 34, 105, 1) 100%);
                     left: 1px;
                     top: 1px;
                     width: calc(100% - 2px);
                     height: calc(100% - 2px);
                     border-radius: 20px;
                     z-index: 1;
                  }
               }

               &.yellowBg {
                  &:before {
                     content: '';
                     position: absolute;
                     background: rgb(118, 107, 80);
                     background: linear-gradient(180deg, rgba(118, 107, 80, 1) 0%, rgba(108, 83, 25, 1) 100%);
                     left: 1px;
                     top: 1px;
                     width: calc(100% - 2px);
                     height: calc(100% - 2px);
                     border-radius: 20px;
                     z-index: 1;
                  }
               }

               &.greyBg {
                  &:before {
                     content: '';
                     position: absolute;
                     background: rgb(132, 132, 132);
                     background: linear-gradient(180deg, rgba(132, 132, 132, 1) 0%, rgba(35, 35, 35, 1) 100%);
                     left: 1px;
                     top: 1px;
                     width: calc(100% - 2px);
                     height: calc(100% - 2px);
                     border-radius: 20px;
                     z-index: 1;
                  }
               }

               &.tm2bg {
                  background: url('../../images/team2.png') no-repeat;
                  background-size: 100% 100%;
               }

               &.tm3bg {
                  background: url('../../images/team3.png') no-repeat;
                  background-size: 100% 100%;
               }

               &.tm4bg {
                  background: url('../../images/team4.png') no-repeat;
                  background-size: 100% 100%;
               }

               .inrCnt {
                  position: relative;
                  z-index: 2;
               }
            }

            .bsform {
               margin-top: 29px;

               .input-group {
                  background: url('../../images/selectbg.png') no-repeat;
                  background-size: 100% 100%;
                  min-width: 342px;
                  min-height: 75px;
                  display: flex;
                  align-items: center;

                  @media(max-width:576px) {
                     min-width: 250px;
                  }

                  span {
                     background: transparent;
                     border: 1px solid transparent;
                     min-height: 75px;
                  }

               }

               .mx342 {
                  max-width: 342px;

                  @media(max-width:576px) {
                     max-width: 200px;
                  }
               }

               .selbg {
                  background: url('../../images/selectbg.png') no-repeat;
                  background-size: 100% 100%;
                  min-height: 72px;
                  min-width: 342px;
                  padding: 22px;

                  @media(max-width:576px) {
                     min-width: 200px;
                  }

                  .dropdown {
                     button {
                        background: transparent;
                        border: 1px solid transparent;
                     }

                  }

                  .dropdown-toggle::after {
                     content: unset;
                  }
               }
            }

            .yelbg {
               border-radius: 10px;
               background: linear-gradient(97.53deg, #c5a252 0%, #f7dc8f 50%, #c5a252 100%);
               min-height: 82px;
               flex-wrap: wrap;
               display: flex;
               align-items: center;
               justify-content: space-between;
               padding: 25px;

               &.mx418 {
                  min-width: 418px;

                  @media(max-width:576px) {
                     min-width: unset;
                  }
               }
            }

            .bnimg {
               background: rgb(218, 218, 243);
               background: linear-gradient(-64deg, rgba(218, 218, 243, 1) 0%, rgba(15, 14, 24, 1) 51%, rgba(218, 218, 243, 1) 100%);
               border-radius: 20px;
               position: relative;
               margin: 5px 0px;
               min-height: 184px;
               padding: 18px;

             
               &.active {
                  position: relative;
                  cursor: pointer;

                  h5,
                  a,
                  h3,
                  .borderbtm {
                     visibility: hidden;

                  }

                  &::after {
                     position: absolute;
                     content: '';
                     background: url('../../images/unlock.png') no-repeat;
                     background-size: 100% 100%;
                     width: 54px;
                     height: 54px;
                     left: 50%;
                     top: 50%;
                     transform: translate(-50%, -50%);
                     z-index: 3;
                  }
               }

               &.blueBg {
                  &:before {
                     content: '';
                     position: absolute;
                     background: rgb(84, 84, 108);
                     background: linear-gradient(180deg, rgba(84, 84, 108, 1) 0%, rgba(34, 34, 105, 1) 100%);
                     left: 1px;
                     top: 1px;
                     width: calc(100% - 2px);
                     height: calc(100% - 2px);
                     border-radius: 20px;
                     z-index: 1;
                  }
               }

               &.yellowBg {
                  &:before {
                     content: '';
                     position: absolute;
                     background: rgb(118, 107, 80);
                     background: linear-gradient(180deg, rgba(118, 107, 80, 1) 0%, rgba(108, 83, 25, 1) 100%);
                     left: 1px;
                     top: 1px;
                     width: calc(100% - 2px);
                     height: calc(100% - 2px);
                     border-radius: 20px;
                     z-index: 1;
                  }
               }

               &.greyBg {
                  &:before {
                     content: '';
                     position: absolute;
                     background: rgb(132, 132, 132);
                     background: linear-gradient(180deg, rgba(132, 132, 132, 1) 0%, rgba(35, 35, 35, 1) 100%);
                     left: 1px;
                     top: 1px;
                     width: calc(100% - 2px);
                     height: calc(100% - 2px);
                     border-radius: 20px;
                     z-index: 1;
                  }
               }

               &.bn1 {
                  background: url('../../images/bn1.png') no-repeat;
                  background-size: 100% 100%;
               }

               &.bn2 {
                  background: url('../../images/bn2.png') no-repeat;
                  background-size: 100% 100%;
               }

               &.bn3 {
                  background: url('../../images/bn3.png') no-repeat;
                  background-size: 100% 100%;
               }

               .inrCnt {
                  position: relative;
                  z-index: 2;
               }
            }

            &.z-4{
               z-index: 4;
            }
         }
      }

      .newSec {
         p {
            font-size: 14px;
         }
      }

      &.underMaintenanceCnt {
         min-height: 100vh;
         display: flex;
         justify-content: center;
         align-items: center;

         img {
            &.lightBlend {
               mix-blend-mode: lighten;
               margin-top: -7%;
            }
         }
      }
   }

   .successTxt {
      color: #05F0BB !important;
   }

   .container {
      @media(min-width:1200px) {
         max-width: 1150px;
      }
   }
}

@keyframes coinflip {
   0% {
      transform: rotateY(0deg);
   }

   100% {
      transform: rotateY(360deg);
   }
}


//july26 //
.img-new {

   width: 520px;
   height: 244px;
   border-radius: 10px;
}

.ttnCnt{
   position: relative;
   padding:25px;
  
   &:after{
   content:'';
   position: absolute;
   right:0px;
   top:50%;
   transform: translateY(-50%);
   width:1px;
   height:33px;
   background: rgba(255,255,255,0.1);
  
   @media(max-width:575px){
   width:33px;
   height:1px;
   right:auto;
   left:0px;
   bottom:0px;
   top:auto;
   transform: none;
   }
   }
   &:last-child{
   &:after{
   display: none;
   }
   }
   h3{
   &.value{
   font-size: clamp(1rem, 0.9518rem + 0.241vw, 1.25rem);
   font-weight:700;
   color:rgba(248, 220, 143, 1)!important;
   margin-top:5px;
   }
   }
   h5{
   &.heading{
   font-size: 14;
   font-weight:400;
   }
   }
   }