.roadmapSec{
   padding:30px 0px;
   position: relative;  

   @media(min-width:1200px){
      padding-top:150px;
   }

   .tknmcsUpperCnt{
      position: relative;
      z-index: 2;
   }

   .roadmapBg{
      position: absolute;
      left:0px;
      bottom:-500px;
      width:100%;
      z-index: 1;
      object-fit:fill;
   }
}

.roadMapScrollCnt{
   display: flex;
   flex-wrap: wrap;
   position: relative;

   &:after{
      content:'';
      position: absolute;
      left:50%;
      top:0px;
      transform: translateX(-50%);
      width:30px;
      height:100%;
      border:1px solid #363636;
      z-index: 1;
      
   }
   .roadMapRunnerCnt{
      position: absolute;
      left:50%;
      transform: translateX(-50%);
      top:0px;
      width:30px;
      height: 100%;
   }
   .roadMapRunner{
      position: sticky;
      left:0;
      top:85px;      
      width:29px;
      height:320px;
      background:url('../../../images/Home/Roadmap/rdMpRibbon.png');
      z-index: 2;
      display: block;
   }

   .roadMapRow{
      width:100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      

      .roadMapCnt{
         width:calc(50% - 15px);
         background: rgb(71,71,71);
         background: linear-gradient(-89deg, rgba(71,71,71,1) 0%, rgba(15,14,24,1) 51%, rgba(71,71,71,1) 100%);
         border-radius: 15px;
         position: relative;
         transition: all 0.5s;
         min-height:180px;
         padding:15px;
         border-top-left-radius: 0px;
         border-bottom-left-radius: 0px;

         @media(max-width:575px){
            border-top-left-radius: 15px;
            border-bottom-left-radius: 15px;
            margin-bottom:15px;
         }

         @media(max-width:575px){
            width:100%;
         }

         &:before{
            content:'';
            position: absolute;
            background:#0E0E10;
            width:calc(100% - 2px);
            height:calc(100% - 2px);
            top:1px;
            left:1px;
            border-radius: 15px;
            border-top-left-radius: 0px;
            border-bottom-left-radius: 0px;
            z-index: 0;
            @media(max-width:575px){
               border-top-left-radius: 15px;
               border-bottom-left-radius: 15px;
            }
         }

         span{
            font-size: clamp(14px, 1.67vw, 20px);
            color:#FFE388;
            position: relative;
            z-index: 1;
         }
         h3{
            font-size: clamp(16px, 2.33vw, 28px);
            color:#ffffff;
            font-weight: 600;
            margin-bottom:0px;
            margin-top:25px;
            position: relative;
            z-index: 1;
         }

         ul{
            padding-left:0px;
            margin-top:20px;
            position: relative;
            z-index: 1;

            li{
               list-style-type: none;
               background: url('../../../images/Home/Roadmap/listArrow.png') no-repeat left center;
               padding-left:25px;
               margin-bottom:5px;
               font-weight:400;
               font-size: 14px;
            }
         }
      }

      &:nth-child(even){
         justify-content: flex-start;

         .roadMapCnt{
            border-top-left-radius: 15px;
            border-bottom-left-radius: 15px;
            border-top-right-radius: 0px;
            border-bottom-right-radius: 0px;

            @media(max-width:575px){
               border-top-right-radius: 15px;
               border-bottom-right-radius: 15px;
            }

            &:before{
               border-top-left-radius: 15px;
               border-bottom-left-radius: 15px;
               border-top-right-radius: 0px;
               border-bottom-right-radius: 0px;

               @media(max-width:575px){
                  border-top-right-radius: 15px;
                  border-bottom-right-radius: 15px;
               }
            }
         }
      }

      &.active{
         .roadMapCnt{
            border-image: linear-gradient(90deg, #474747, #FFE389, #474747) 12;
            border-width: 1px;
            border-style: solid;
            background: #0E0E10;
            border-radius:15px!important;
         }
      }

      &.cntr{
         justify-content: center!important;
         z-index: 99;
         margin-top:25px;

         .roadMapCnt{
            border-radius:15px!important;
            background: rgb(197,162,82);
            background: linear-gradient(-89deg, rgba(197,162,82,1) 0%, rgba(248,220,143,1) 50%, rgba(197,162,82,1) 100%);
            min-height:unset;
            width:400px;
            &:before{
               border-radius:15px!important;
               background: rgb(197,162,82);
               background: linear-gradient(-89deg, rgba(197,162,82,1) 0%, rgba(248,220,143,1) 50%, rgba(197,162,82,1) 100%);           
            }

            span{
               color:#000000;
               font-weight: 600;
            }
            ul{
               margin-top:10px;
               li{
                  color:#000000;
                  background: none;
                  padding-left:0px;
                  font-weight: 500;
                  text-align: center;
               }
            }
            @media(max-width:575px){
               width:100%;
            }
         }
      }
   }
}