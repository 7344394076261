.home_wrap {
   position: relative;

   .bnr{
      background:#060609;
      padding-top:88px;
      position: relative;
      min-height: 475px;
      overflow: hidden;

      @media(max-width:991px){
         padding-top:45px;
      }

      

      .container{
         position: relative;
         z-index: 2;
      }      

      

      .bannerRightCnt{
         position: absolute;
         top:50%;
         right:-13px;
         z-index: 2;
         transform: scale(1.10) translateY(-42%);
         width: 50%;
         mix-blend-mode: lighten;

         @media(max-width:991px){
            position: relative;
            width: 80%;
            margin-left:auto;
            top:auto;
            transform: none;
            margin-top:0px;
            right:-45px;
         }
         @media(max-width:575px){
            width:100%;
         }

         video{
            width: 100%;
            height: 100%;
            object-fit: contain;
         }

         .bannerInnerCnt{
            position:relative;
            text-align: right;

            .bannerHand{
               @media(max-width:1199px){
                  width:450px;
                  height: auto;
               }
               @media(max-width:575px){
                  width:300px;
                  height: auto;
                  display: none;
               }
            }

            .bannerCoin{
               position: absolute;
               top: -7px;
               left: -85px;

               @media(max-width:1199px){
                  top: -64px;
                  left: -102px;
               }

               @media(max-width:991px){
                  left:auto;
                  right:320px;
               }
               @media(max-width:575px){
                  right: 183px;
                  top: -104px;
                  display: none;
               }
            }
         }
      }

      
   }

   &.scrolled{
      .indHd{
         position: relative;
         &:before{
            content:'';
            background:rgba(255,255,255,0.1);
            -webkit-backdrop-filter: blur(15px);
            backdrop-filter: blur(15px); 
            position: absolute;  
            width: 100%;
            height: 100%;
            top:0px;
            left:0px;
         }
         
      }
   }
   .rdNwSec{
      background:url('../../images/Home/roadmapBg.png') no-repeat bottom center;
      background-size: cover;
   }

   .homeBannerSec{
      position: relative;
      overflow: hidden;
      &:before{
         content:'';
         position: absolute;
         left:0px;
         bottom:0px;
         width:100%;
         height:130px;
         background: rgb(6,6,9);
         background: linear-gradient(0deg, rgba(6,6,9,1) 0%, rgba(0,0,0,0) 100%);
         z-index:1;
      }
      .bnrBg{
         position:absolute;
         left:0px;
         top:0px;
         width:100%;
         height:100%;
         mix-blend-mode: lighten;
         opacity: 0.3;
         z-index: 0;
         object-fit: cover;
         object-position: bottom center;
      }
      .bnrShadow{
         position: absolute;
         top:0px;
         left:50%;
         transform: translateX(-50%);
      }
   }
}


html.has-scroll-smooth {
   overflow: hidden; 
 }
 
 html.has-scroll-dragging {
   -webkit-user-select: none;
   -moz-user-select: none;
   -ms-user-select: none;
   user-select: none; 
 }
 
 .has-scroll-smooth body {
   overflow: hidden; 
 }
 
 .has-scroll-smooth [data-scroll-container] {
   min-height: 100vh; 
 }
 
 [data-scroll-direction="horizontal"] [data-scroll-container] {
   height: 100vh;
   display: inline-block;
   white-space: nowrap; 
 }
 
 [data-scroll-direction="horizontal"] [data-scroll-section] {
   display: inline-block;
   vertical-align: top;
   white-space: nowrap;
   height: 100%; 
 }
 
 .c-scrollbar {
   position: absolute;
   right: 0;
   top: 0;
   width: 11px;
   height: 100%;
   transform-origin: center right;
   transition: transform 0.3s, opacity 0.3s;
   opacity: 0; 
 }
 
   .c-scrollbar:hover {
     transform: scaleX(1.45); 
 }
 
   .c-scrollbar:hover, .has-scroll-scrolling .c-scrollbar, .has-scroll-dragging .c-scrollbar {
     opacity: 1; 
 }
 
 [data-scroll-direction="horizontal"] .c-scrollbar {
     width: 100%;
     height: 10px;
     top: auto;
     bottom: 0;
     transform: scaleY(1); 
 }
 
 [data-scroll-direction="horizontal"] .c-scrollbar:hover {
       transform: scaleY(1.3); 
 }
 
 .c-scrollbar_thumb {
   position: absolute;
   top: 0;
   right: 0;
   background-color: black;
   opacity: 0.5;
   width: 7px;
   border-radius: 10px;
   margin: 2px;
   cursor: -webkit-grab;
   cursor: grab; 
 }
   .has-scroll-dragging .c-scrollbar_thumb {
     cursor: -webkit-grabbing;
     cursor: grabbing; 
 }
   [data-scroll-direction="horizontal"] .c-scrollbar_thumb {
     right: auto;
     bottom: 0; 
 }