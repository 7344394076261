.ftadRf {
   @media only screen and (min-width: 1200px) {
      margin-bottom: -4.625rem;
   }

   @media only screen and (max-width: 1199px) {
      margin-bottom: 2rem;
   }

   position: relative;
}

.adRfBx {
   background: linear-gradient(97.53deg, #C5A252 6.3%, #F8DC8F 53.15%, #C5A252 100%);
   border-radius: 20px;
   padding: 1.75rem 3.75rem;
   max-width: 956px;
   margin: 0 auto;

   @media only screen and (max-width: 991px) {
      padding: 1.75rem;
      text-align: center;
   }

   @media only screen and (max-width: 575px) {
      padding: 1.25rem;
      border-radius: 12px;
   }
}

.adRfBxTx {
   font-size: 28px;
   background: linear-gradient(91.74deg, rgba(0, 0, 0, 0.6) 0.35%, #000000 52.38%, rgba(0, 0, 0, 0.6) 99.45%);
   -webkit-background-clip: text;
   -webkit-text-fill-color: transparent;
   font-family: var(--font-druk);
   line-height: normal;
   margin-bottom: 16px;

   @media only screen and (max-width: 991px) {
      font-size: 34px;
   }

   @media only screen and (max-width: 767px) {
      font-size: 28px;
   }

   @media only screen and (max-width: 575px) {
      font-size: 24px;
   }
}

.adRfBxIp .input-group {
   border: 1px solid #000000;
   padding: 5.5px 12px;
   border-radius: 57px;

   @media only screen and (min-width: 992px) {
      max-width: 481px;
   }
}

.adRfBxIp .input-group .form-control {
   background-color: transparent;
   border: 0px;
   font-weight: 600;
   font-size: 20px;
   color: #000000;
   box-shadow: none;
   overflow: hidden;
   text-overflow: ellipsis;

   @media only screen and (max-width: 767px) {
      font-size: 18px;
   }

   @media only screen and (max-width: 575px) {
      font-size: 16px;
   }
}

.adRfBxIp .btn {
   font-weight: 700;
   font-size: 20px;
   text-decoration-line: underline;
   color: #000000;
   box-shadow: none;

   @media only screen and (max-width: 767px) {
      font-size: 18px;
   }

   @media only screen and (max-width: 575px) {
      font-size: 16px;
   }
}

.adRfBxIp .btn:hover {
   text-decoration: none;
}
.adRfBxIp{
   p{
      font-size:14px;
      color:rgba(0,0,0,0.6);
      font-weight: 400;
   }
}

.btn-blkOl {
   border: 1px solid #000000;
   color: #060608;
   box-shadow: none;
}

.btn-blkOl:hover {
   background-color: #000000;
   color: #ffffff;

   img{
      filter:brightness(0) invert(1);
   }
}

.btn-20555 {
   min-width: 205px;
   font-weight: 600;
   font-size: 14px;
   padding: 11.5px 12px;
   border-radius: 55px;

   @media only screen and (max-width: 767px) {
      font-size: 18px;
      min-width: 185px;
   }

   @media only screen and (max-width: 575px) {
      font-size: 16px;
      min-width: 165px;
   }
}

.ftrBx {
   border: 1px solid transparent;
   background-color: #0C0C0C;
   border-image-slice: 1;
   border-radius: 30px;
   background-image: linear-gradient(45deg, #0C0C0C, #0C0C0C), linear-gradient(90.38deg, #474747 0.24%, #0F0E18 50.92%, #474747 99.62%);
   background-clip: padding-box, border-box;
   padding: 46px 56px 46px;

   @media only screen and (min-width: 1200px) {
      padding-top: 130px;
   }

   @media only screen and (max-width: 991px) {
      padding: 46px;
   }

   @media only screen and (max-width: 575px) {
      padding: 26px;
      border-radius: 12px;
   }
}

.ftMenuTl {
   font-weight: 600;
   font-size: 18px;
   line-height: normal;
   padding: 8px 0px 10px;
}

.ftMenu ul {
   list-style: square;
   color: var(--white-06);
   padding-left: 18px;
   margin: 0px;
}

.ftMenu ul li a {
   font-weight: 300;
   font-size: 14px;
   line-height: 26px;
   text-decoration: none;
   color: var(--white-06);
}

.ftMenu ul li a:hover {
   color: #ffffff;
}

.ftMenu ul li {
   padding: 4px 0px;
}

.ftCon {
   text-align: center;
   max-width: 350px;
   margin: 0 auto;
}

.ftLogo {
   margin-bottom: 2rem;

   @media only screen and (max-width: 575px) {
      margin-bottom: 1rem;
   }
}

.ftTx {
   font-weight: 300;
   font-size: 14px;
   line-height: 26px;
   color: var(--white-06);
   padding-bottom: 4px;
}

.ftScl ul {
   display: flex;
   flex-wrap: wrap;
   align-items: center;
   justify-content: center;
   padding: 0px;
   margin: 0px;
   list-style: none;
   gap: 10px;

   @media only screen and (max-width: 575px) {
      gap: 10px 6px;
   }
}

.ftScl ul li button {
   display: inline-flex;
   align-items: center;
   justify-content: center;
   background-color: #060608;
   width: 35px;
   height: 35px;
   border-radius: 50%;
   backdrop-filter: blur(26.975967407226562px);
   border: 1px solid transparent !important;
   background-image: linear-gradient(45deg, #0C0C0C, #0C0C0C), linear-gradient(90deg, #474747 0.24%, #0F0E18 50.92%, #474747 99.62%);
   background-clip: content-box, border-box;
   transition: 0.3s;
}

.ftScl ul li button:hover {
   background-image: linear-gradient(130.81deg, #c5a252 17.78%, #f8dc8f 58.89%, #c5a252 100%);
}

.ftScl ul li button img {
   transition: 0.3s;
}

.ftScl ul li button:hover svg {
   filter: invert(1);
}

.ftScl ul li button:not(:hover) img {
   filter: invert(1);
   transition: 0.3s;
}

.ftScl ul li a {
   display: inline-flex;
   align-items: center;
   justify-content: center;
   background-color: #060608;
   width: 48px;
   height: 48px;
   border-radius: 50%;
   backdrop-filter: blur(26.975967407226562px);
   border: 1px solid transparent !important;
   background-image: linear-gradient(45deg, #0C0C0C, #0C0C0C), linear-gradient(90deg, #474747 0.24%, #0F0E18 50.92%, #474747 99.62%);
   background-clip: content-box, border-box;
   transition: 0.3s;
}

.ftScl ul li a:hover {
   background-image: linear-gradient(130.81deg, #c5a252 17.78%, #f8dc8f 58.89%, #c5a252 100%);
}

.ftScl ul li a img {
   transition: 0.3s;
}

.ftScl ul li a:not(:hover) img {
   filter: invert(1);
   transition: 0.3s;
}