.newsSec{
   padding:30px 0px;

   .newsCnt{
      margin-bottom:15px;
      padding:0px 7.5px;
      img{
         &.newsBanner{
            margin-bottom:25px;
            border-radius:23px;
            width:100%;
         }
      }
   
      h3{
         font-size: 16px;
         margin-bottom:10px;
         color:#ffffff;
         font-weight:500;
      }
      h5{
         font-size: 14px;
         margin-bottom:10px;
         color:#FFE388;
         font-weight:400;
      }
      p{
         font-size: 14px;
         color: var(--white-06);
         font-weight:300;
         margin-bottom:15px;
         text-transform: capitalize;
      }
      a{
         font-size:14px;
         color:#FFE388;
         text-decoration: underline;
      }
   }
}