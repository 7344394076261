.indHd {
   padding: 20px 0px;

   &.home {
      position: relative;
      top: 0px;
      left: 0px;
      width: 100%;
      z-index: 9;
      transition: all 0.2s;
   }
}

.hdMenu {
   padding: 0px;
   margin: 0px;
   list-style: none;
   display: flex;
   align-items: center;
   justify-content: center;
   flex-wrap: wrap;
   gap: 1.2rem;

   @media only screen and (max-width: 992px) {
      flex-direction: column;
   }
}

.hdMenu li a {
   color: #ffffff;
   font-size: 14px;
   text-decoration: none;
}

.hdMenu li a.active,
.hdMenu li a:hover {
   color: #FFE389;
}

.hdRgt {
   text-align: right;
   display: flex;
   align-items: center;
   justify-content: flex-end;
   gap: 1rem;

   .dropdown {
      .btn-11545 {
         height: 30px;
         padding: 2px 12px;
         min-width: unset;
         font-size: 14px;

         &:focus {
            color: #000000;
         }
      }

      &.prfdd {
         background: rgb(255, 227, 137);
         background: linear-gradient(-89deg, rgba(71, 71, 71, 1) 0%, rgba(255, 227, 137, 1) 51%, rgba(71, 71, 71, 1) 100%);
         position: relative;
         border-radius: 45px;

         &:before {
            content: '';
            position: absolute;
            left: 1px;
            top: 1px;
            width: calc(100% - 2px);
            height: calc(100% - 2px);
            z-index: 1;
            background-color: #0E0E10;
            border-radius: 45px;
         }

         .btn-11545 {
            position: relative;
            z-index: 2;
            padding: 2px 5px;
            background: transparent;
            border-color: transparent;

            &:focus {
               color: #ffffff;
            }
         }
      }

      .iconToggle {
         background: transparent;
         border: none;
         padding: 0px;

         &:focus {
            box-shadow: none;
         }
      }

      .dropdown-menu {
         top: 10px !important;
         background: rgb(71, 71, 71);
         background: linear-gradient(-88deg, rgba(71, 71, 71, 1) 0%, rgba(15, 14, 24, 1) 51%, rgba(71, 71, 71, 1) 100%);
         border-radius: 10px;
         position: relative;

         &::before {
            content: '';
            position: absolute;
            left: 1px;
            top: 1px;
            width: calc(100% - 2px);
            height: calc(100% - 2px);
            z-index: 1;
            background-color: #0C0C0C;
            border-radius: 10px;
         }

         a {
            position: relative;
            z-index: 3;
            color: #fff;
            line-height: 24px;
            border-bottom: 1px solid rgba(255, 255, 255, 0.1);
            width: 100%;
            display: block;

            &:last-child {
               border-bottom: 0px;
            }

            &:hover {
               color: #FFE389;
               background: transparent;
               cursor: pointer;
            }
         }
      }
   }
}


.hdMenuPt {
   @media only screen and (max-width: 992px) {
      position: fixed;
      top: 0px;
      left: 0px;
      width: 280px;
      height: 100%;
      padding: 1rem;
      background-color: #0e0c08;
      z-index: 999;
      transform: translateX(-100%);
      transition: 0.3s transform;

      .sbMenu & {
         transform: translateX(0%);
      }
   }
}


.mobMenu {
   display: inline-block;
   vertical-align: middle;
}

.smClose {
   width: 40px;
   height: 40px;
   z-index: 999;
   overflow: hidden;
   display: inline-block;
   vertical-align: middle;
   background: transparent;
   border: none;

   &:focus,
   &:focus-visible {
      outline: none;
   }
}

#closeicon {
   width: 40px;
   height: 40px;
   cursor: pointer;
   -webkit-transform: translate3d(0, 0, 0);
   -moz-transform: translate3d(0, 0, 0);
   -o-transform: translate3d(0, 0, 0);
   -ms-transform: translate3d(0, 0, 0);
   transform: translate3d(0, 0, 0);
   transform: scale(2) translate(0px, -1px);
   position: relative;
}

#closeicon path {
   fill: none;
   -webkit-transition: stroke-dashoffset 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25), stroke-dasharray 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25);
   -moz-transition: stroke-dashoffset 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25), stroke-dasharray 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25);
   -o-transition: stroke-dashoffset 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25), stroke-dasharray 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25);
   -ms-transition: stroke-dashoffset 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25), stroke-dasharray 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25);
   transition: stroke-dashoffset 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25), stroke-dasharray 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25);
   stroke-width: 20px;
   stroke-linecap: round;
   stroke: #ffffff;
   stroke-dashoffset: 0px;
}

#closeicon path#top,
#closeicon path#bottom {
   stroke-dasharray: 240px 950px;
}

#closeicon path#middle {
   stroke-dasharray: 240px 238px;
}

.smClose.active #closeicon path#top,
.smClose.active #closeicon path#bottom {
   stroke-dashoffset: -650px;
}

.smClose.active #closeicon path#middle {
   stroke-dashoffset: -115px;
   stroke-dasharray: 1px 220px;
}

.innerpagesSec {
   .indHd {
      background: rgba(89, 86, 75, 0.25);
   }
}

