.hmStk {
   padding: 19px 0px;

   @media only screen and (min-width: 992px) {
      padding: 19px 0px 17.5vw;
   }

   position: relative;
}

.hmStkHd {
   padding-bottom: 14px;
}

.hmStkAlcBx {
   @media only screen and (min-width: 768px) {
      display: inline-block;
   }

   background: rgba(39, 39, 39, 0.25);
   padding: 15px;
   border-radius: 20px;
   min-width: 332px;
}

.hmStkNb {
   font-size: 38px;
   font-family: var(--font-druk);
   line-height: normal;
}

.hmStkNb span {
   -webkit-text-fill-color: #0e0e11;
   background: linear-gradient(115.48deg, #000000 3.54%, #FFFFFF 56.28%, #000000 98.58%);
   -webkit-background-clip: text;
   -webkit-text-stroke: 2px transparent;
   color: #b7bdbd;
}

.hmStkNbTxG {
   display: inline-block;
   text-align: center;
}

.hmStkNbTx {
   font-weight: 600;
   font-size: 14px;
   color: #ffffff;
   line-height: normal;
}

.hmStkAlcLb {
   font-weight: 600;
   font-size: 14px;
   line-height: normal;
   margin-bottom: 4px;
}

.hmStkAlcLbAm {
   text-align: center;
   padding: 0px 2px;
   position: relative;
   width: 100%;
}

.hmStkAlcAm {
   display: inline-flex;
   align-items: center;
   gap: 4px;
   color: #f7dc8f;
   font-weight: 700;
   font-size: 20px;
   line-height: 1;
}

.hmStkAlcAm img {
   min-width: 20px;
   height: 20px;
}

.hmStkAlcAm span {
   display: inline-block;

   &:first-child {
      font-size: 0px;
   }
}

.hmStkAlcBxTp {
   padding-bottom: 1rem;
   border-bottom: 1px solid rgba(255, 255, 255, 0.1);
   margin-bottom: 1rem;
}

.hmStkLkTx {
   display: flex;
   align-items: center;
   gap: 4px;
   font-size: 14px;
   color: #00C537;
   &:not(:last-child)
   {
      padding-bottom: 4px;
   }
}

.hmStkLkTx.v2 {
   color: #ff4949;
}

.hmStkAlcBxBm .row>*:nth-child(odd) {
   &::after {
      content: '';
      position: absolute;
      top: 50%;
      right: 7.5px;
      background-color: rgba(255, 255, 255, 0.1);
      width: 1px;
      height: 100%;
      transform: translate(0%, -50%);
   }
}

.hmStkAlcBxBm {
   padding-bottom: 10px;
}




.hmStkDtsCd {
   display: flex;
   gap: 1rem;
   padding-bottom: 20px;
}

.hmStkDtsIc {
   min-width: 49px;
}

.hmStkDtsTx h5 {
   font-weight: 600;
   font-size: 20px;
   line-height: normal;
   margin-bottom: 4px;
}

.hmStkDtsTx p {
   font-weight: 300;
   font-size: 14px;
   line-height: 1.75;
   text-transform: capitalize;
}

.hmStkDts {
   padding-left: 20px;
   padding-top: 48px;
}





.hmStkImgs {
   position: absolute;
   bottom: -9.5vw;
   text-align: center;
   right: 0px;

   @media only screen and (max-width: 991px) {
      display: none;
   }
}

.hmStkImg1 img,.hmStkImg1 video {
   mix-blend-mode: screen;
}
.hmStkImg1 video{
   width:100%;
}

.hmStkImg2 {
   position: absolute;
   top: 25%;
   left: 58%;
   transform: translate(-50%, -50%);
   max-width: 298px;
}

.hmStkImgsCnt {
   display: inline-block;
   position: relative;
   transform: translate(-110px, 0px);
   background-color: #060609;
}




.hmStkImg2Rv {
   position: absolute;
   top: 67%;
}

.hmStkImg2Rv img {
   opacity: 0.1;
   mix-blend-mode: screen;
   transform: rotateX(180deg);
   -webkit-mask-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 24%, #000000 60%);
   mask-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 24%, #000000 60%);
}