@font-face {
   font-family: 'Druk Wide Heavy Trial';
   src: url('../fonts/DrukWide-Heavy.eot');
   src: url('../fonts/DrukWide-Heavy.eot?#iefix') format('embedded-opentype'),
      url('../fonts/DrukWide-Heavy.woff2') format('woff2'),
      url('../fonts/DrukWide-Heavy.woff') format('woff'),
      url('../fonts/DrukWide-Heavy.ttf') format('truetype'),
      url('../fonts/DrukWide-Heavy.svg#DrukWide-Heavy') format('svg');
   font-weight: 900;
   font-style: normal;
   font-display: swap;
}

body {
   background-color: #060609;
   color: #ffffff;
   font-family: "Sora", sans-serif;
   --font-druk: 'Druk Wide Heavy Trial', sans-serif;
   font-size:14px;
}

:root {
   --white-06: rgba(255, 255, 255, 0.6);
   --ylwTxt: #FFE389;
}


// Enhancement Grid styles
.row {
   --bs-gutter-x: 1.875rem;
   --bs-gutter-y: 0;
   margin-left: calc(var(--bs-gutter-x)* -.5);
   margin-right: calc(var(--bs-gutter-x)* -.5);
   margin-top: calc(var(--bs-gutter-y)* -1);
}

.row>* {
   margin-top: var(--bs-gutter-y);
   padding-left: calc(var(--bs-gutter-x)* .5);
   padding-right: calc(var(--bs-gutter-x)* .5);
}

.gy-2 {
   --bs-gutter-y: 0.5rem;
}

.gy-3 {
   --bs-gutter-y: 1rem;
}

.gy-4 {
   --bs-gutter-y: 1.5rem;
}

/*Btn styles*/
.btnLink {
   color: #ffffff;
   font-size: 14px;
   box-shadow: none;
}

.btnLink:hover {
   color: #FFE389;
   text-decoration: none;
}

.btnGold {
   background-image: linear-gradient(97.53deg, #C5A252 6.3%, #F8DC8F 53.15%, #C5A252 100%);
   border: 2px solid transparent;
   color: #000000;
   font-weight: 600;
   border-radius:50px;
}

.btnGold:hover {
   background:transparent;
   color: #F8DC8F;
   text-decoration: none;
   border-color:#F8DC8F;
}
.btnGoldOutline {
   border: 2px solid#F8DC8F;
   color: #F8DC8F;
   font-weight: 600;
   border-radius:50px;
}

.btnGoldOutline:hover {
   background-image: linear-gradient(-97.53deg, #C5A252 6.3%, #F8DC8F 53.15%, #C5A252 100%);
   color: #000000;
   text-decoration: none;
}


.btn-11545 {
   min-width: 115px;
   padding: 10.5px 12px;
   border-radius: 45px;

   &.btnLg{
      min-width:190px;
   }

   @media(max-width:991px){
      padding: 3.5px 12px;
   }
}

// Text Styles
.txt-gradient {
   -webkit-background-clip: text;
   -webkit-text-fill-color: transparent;
}
.greyTxt{
   color:var(--white-06)!important;
}
.whiteTxt{
   color:#ffffff!important;
}
.prmyTxt{
   color:#FFE389!important;
}
.lgTxt{
   font-size: clamp(16px, 1.67vw, 20px)!important;
}
.xlTxt{
   font-size: clamp(20px, 4.00vw, 48px);
}


.secTlTg span {
   position: relative;
   font-size: 14px;
   font-weight: 600;
   text-align: center;
   border: 1px solid transparent;
   background-image: linear-gradient(45deg, #0C0C0C, #0C0C0C), linear-gradient(90.38deg, #474747 0.24%, #0F0E18 50.92%, #474747 99.62%);
   background-clip: padding-box, border-box;
   padding: 11px 10px;
   border-radius: 45px;
   display: inline-flex;
   align-items: center;
}

.secTlTg span::before,
.secTlTg span::after {
   content: '';
   width: 5px;
   height: 5px;
   background: linear-gradient(97.53deg, #C5A252 6.3%, #F8DC8F 53.15%, #C5A252 100%);
   display: inline-block;
   border-radius: 50%;
   vertical-align: middle;
   margin: 0px 1.25rem;
}

.secTl {
   font-size: 30px;
   font-family: var(--font-druk);
}

.secTl span {
   background-image: linear-gradient(91.74deg, #9E9E9E 0.35%, #FFFFFF 52.38%, #9E9E9E 99.45%);
   display: inline-block;
}

.secPara {
   font-size: 16px;
   color: var(--white-06);
   line-height: 1.75;
   font-weight: 400;
}

.yellowTxt{
   color:#F8DC8F!important;
}
.uppercase{
   text-transform: uppercase;
}
.greyIcon{
   filter:brightness(0);
}
/*Auth pages styles*/
.authModal{
   .modal-dialog{
      max-width:100%;
      margin:0px;
      min-height:100vh;

      .modal-content{
         min-height:100vh;
         border-radius:0px;
         border:none;
         background:#09090B url('../images/Auth/loginBg.png') no-repeat center bottom;

         .modal-header{
            border-bottom:0px;
            padding:2rem 2rem;
         }

         .modal-body{
            h3{
               &.modalHeading{
                  font-size:38px;
                  font-weight:900;
                  font-family: var(--font-druk);
                  background: -webkit-linear-gradient(-85deg, rgba(71,71,71,1) 0%, rgba(255,255,255,1) 50%, rgba(158,158,158,1) 100%);
                  -webkit-background-clip: text;
                  -webkit-text-fill-color: transparent;
                  text-align:center;
                  padding-bottom:40px;
                  margin-bottom: 15px;
                  position:relative;

                  &::before{
                     content:'';
                     width:168px;
                     height: 1px;
                     background:var(--white-06);
                     position: absolute;
                     left:50%;
                     bottom:0px;
                     transform: translateX(-50%);
                  }
               }
            }
            p{
               &.modalSubHeading{
                  font-size:16px;
                  color:var(--white-06);
                  text-align:center;
                  font-family: "Sora", sans-serif;
                  letter-spacing:0.05rem;
               }
            }
         }

         .close{
            width:26px;
            background:url('../images/Auth/modalClose.png') no-repeat center center;
            background-size:contain;
            span{
               display:none;
            }
         }
      }

      /* Checkbox cnt */
      .checkCnt {
         display: block;
         position: relative;
         padding-left: 25px;
         margin: 4px 0px;
         cursor: pointer;
         font-size: 14px;
         -webkit-user-select: none;
         -moz-user-select: none;
         -ms-user-select: none;
         user-select: none;
      }
      
      /* Hide the browser's default checkbox */
      .checkCnt input {
         position: absolute;
         opacity: 0;
         cursor: pointer;
         height: 0;
         width: 0;
      }
      
      /* Create a custom checkbox */
      .checkmark {
         position: absolute;
         top: 0;
         left: 0;
         height: 18px;
         width: 18px;
         background-color: rgba(255,255,255,0.05);
         border:1px solid rgba(255,255,255,0.1);
         border-radius: 5px;
      }
      
      /* On mouse-over, add a grey background color */
      .checkCnt:hover input ~ .checkmark {
         background-color: #000000;
      }
      
      /* When the checkbox is checked, add a blue background */
      .checkCnt input:checked ~ .checkmark {
         background-color: #F8DC8F;
      }
      
      /* Create the checkmark/indicator (hidden when not checked) */
      .checkmark:after {
         content: "";
         position: absolute;
         display: none;
      }
      
      /* Show the checkmark when checked */
      .checkCnt input:checked ~ .checkmark:after {
         display: block;
      }
      
      /* Style the checkmark/indicator */
      .checkCnt .checkmark:after {
         left: 6px;
         top: 3px;
         width: 5px;
         height: 10px;
         border: solid #000000;
         border-width: 0 3px 3px 0;
         -webkit-transform: rotate(45deg);
         -ms-transform: rotate(45deg);
         transform: rotate(45deg);
      }
   }
}


form{
   label{
      &.formLabel{
         font-size:14px;
         line-height:28px;
         color:#ffffff;
         margin-bottom: 10px;
      }
   }
   input{
      &.form-control{
         height:45px;
         background:rgba(255,255,255,0.05);
         border:1px solid rgba(255,255,255,0.1);
         border-radius:5px;
         color:#ffffff;

         &:focus{
            background:rgba(255,255,255,0.05);
            color:#ffffff;
            box-shadow:none;
            border-color:rgba(255,255,255,0.1);
         }

         &[type="date"]{
            background:rgba(255,255,255,0.05) url('../images/Auth/calendarIcon.png') no-repeat center right 10px;
         }
         &[type="date"]::-webkit-inner-spin-button {
            display: none;
          }
          
         &[type="date"]::-webkit-calendar-picker-indicator {
         opacity: 0;
         }
      }   
   }
   .input-group{
      input{
         border-right:0px;
      }
      .input-group-text{
         background:rgba(255,255,255,0.05);
         border:1px solid rgba(255,255,255,0.1);
         border-left:0px;
         border-radius:5px;
         color:#ffffff;
         font-size:14px;

         span{
            font-size: 14px;
         }
      }
   }
   a{
      font-size:14px;
      color:#ffffff;
      line-height:28px; 

      &.yellowTxt{
         color: #f8dc8f;
         
         &:hover{
            text-decoration: underline;
         }
      }
   }
   select{
      &.form-control{
         height:45px;
         background:rgba(255,255,255,0.05) url('../images/Auth/selectArrow.png') no-repeat center right 10px;
         border:1px solid rgba(255,255,255,0.1);
         border-radius:5px;
         color:#ffffff;
         appearance:none;
      }
   }
   .btn-11545{
      min-width:135px;
   }
}
.cardLabel{
   font-size:14px;
   color:rgba(255,255,255,0.5);
   line-height: 24px;
   font-weight:300;
}
.cardValue{
   font-size:16px;
   color:rgba(255,255,255,1);
   line-height: 24px;
}

//july22----------------------------------//
.faqContainer {
   display: flex;
   flex-direction: row;
   flex-wrap: wrap;
   gap: 20px;
   padding: 20px;
}

.faqRow {
   flex: 1 1 calc(50% - 20px);
   padding: 10px;
}

.faqRow p {
   color: lightgray;
}
.container{
   @media(min-width:1200px){
      max-width:79%;
   }
}
@keyframes blink {
   0% {
       opacity: 1;
   }

   50% {
       opacity: 0;
   }

   100% {
       opacity: 1;
   }
}
.blinkText {
   animation: blink 1s infinite;
}