// Banner Start
.bnrTx {
   position: relative;
   z-index: 9;

   p{
      font-size: 16px;
      color: var(--white-06);
      font-weight: 300;
      letter-spacing: 0.05rem;
      line-height: 28px;
      // text-transform: capitalize;
      margin-bottom:40px;
   }

   .btn{
      width:192px;
      height:55px;
      padding:15px;
      display:flex;
      justify-content: center;
      align-items: center;
   }
}

.bnrTx h3 {
   margin: 0px;

   span {
      position: relative;
      display: inline-block;
      color: #ffffff;
      font-family: var(--font-druk);
      font-size: 16px;
      text-transform: uppercase;
      letter-spacing: 0.05rem;
   }
}

.bnrTx h3 span img {
   position: absolute;
   z-index: -1;
   left: 0px;
   top: 50%;
   transform: translate(-40%, -43%);
}

.bnrTx h1 span {
   background-image: linear-gradient(157.29deg, #C5A252 15.06%, #F8DC8F 52.09%, #C5A252 89.12%);
   font-size: clamp(24px, 4.75vw, 57px);
   -webkit-background-clip: text;
   -webkit-text-fill-color: transparent;
   font-family: var(--font-druk);
   text-shadow: 0px 6px 14px #DBBB6D21;
}

.bnrShp {
   transform: rotate(2.53deg);
   mix-blend-mode: screen;
   margin-top: -7.25rem;
}

.bnrIgs {
   position: relative;
}

// Banner End