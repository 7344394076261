@-webkit-keyframes huecolors {
   0% {
       filter: hue-rotate(0deg);
   }

   25% {
       filter: hue-rotate(360deg);
   }

   50% {
       filter: hue-rotate(0deg);
   }

   75% {
       filter: hue-rotate(360deg);
   }

   100% {
       filter: hue-rotate(0deg);
   }
}

@keyframes huecolors {
   0% {
       filter: hue-rotate(0deg);
   }

   25% {
       filter: hue-rotate(90deg);
   }

   50% {
       filter: hue-rotate(180deg);
   }

   75% {
       filter: hue-rotate(270deg);
   }

   100% {
       filter: hue-rotate(360deg);
   }
}
.tokenomicsSec{
   padding:30px 0px;
}
.borderedCnt{
   background:transparent;
   border:1px solid #474747;
   width:100%;
   border-radius:20px;
   position:relative;

   &.lgCnt{
      &:before{
         content:'';
         position:absolute;
         background:rgba(14,14,16,1) url('../../../images/Home/Tokenomics/tokenomicsBackground.png') no-repeat center left;
         left:0px;
         top:0px;
         width:calc(100% - 0px);
         height:calc(100% - 0px);
         border-radius:20px;
         z-index: 1;
         filter: blur(3px);
         -webkit-filter: blur(3px);
      }
   }     
}

.tokenomicsCnt{   
   overflow: hidden;
   >.row{
      position: relative;
      z-index: 2;
   }

   .coinStructureCnt{
      width:100%;
      padding:25px 15px;

      @media(min-width:1200px){
         padding: 30px 30px;
         padding-top: 50px;
      }

      @media(min-width: 1200px) and (max-width: 1439px){
         padding: 20px;
      }

      h3{
         font-size: clamp(20px, 2.83vw, 34px);
         color:#ffffff;
         font-weight:600;
         text-align:left;
      }

      p{
         font-size: 14px;
         color:var(--white-06);
         font-weight: 300;
         // text-transform: capitalize;
      }

      .coinStructCnt{
         background: rgb(71,71,71);
         background: linear-gradient(-89deg, rgba(71,71,71,1) 0%, rgba(255,227,137,1) 51%, rgba(71,71,71,1) 100%);
         // min-height:102px;
         font-size: clamp(14px, 1.33vw, 16px);
         color:#ffffff;
         font-weight:400;
         width:calc(50% - 15px);
         position:relative;
         border-radius:20px;
         margin:7.5px;
         padding:20px;

         @media(min-width: 1200px) and (max-width: 1439px){
            font-size: 16px;
            padding: 16px;
         }

         @media(max-width:575px){
            width:calc(100% - 15px);
         }

         &:before{
            content:'';
            position: absolute;
            background:#0E0E10;
            height:calc(100% - 2px);
            width:calc(100% - 2px);
            top:1px;
            left:1px;
            border-radius:20px;
         }

         &:nth-child(odd){
            margin-left:0px;

            @media(max-width:575px){
               margin-left:0px;
               margin-right:0px;
            }
         }
         &:nth-child(even){
            margin-right:0px;

            @media(max-width:575px){
               margin-left:0px;
               margin-right:0px;
            }
         }

         h3{
            font-size: clamp(16px, 2.00vw, 18px);
            font-weight:700;
            color:#FFE388;
            // margin:10px 0px;
            margin-bottom:0px;
            text-align: left;
            @media(min-width: 1200px) and (max-width: 1439px){
               font-size: 16px;
            }
            @media(min-width:992px) and (max-width: 1199px){
               font-size: 15px;
            }
         }

         div,h3{
            position: relative;
            z-index: 1;
         }
      }
   }

   .tokenomicsRoboImgCnt{
      position:relative;

      &:before{
         content:'';
         position: absolute;
         left:0px;
         top:0px;
         width:100%;
         height:100%;
         z-index: 2;
         background:rgba(14,14,16,1) url('../../../images/Home/Tokenomics/tokenomicsVideoBg.png') no-repeat center center;
         background-size: cover;
      }     

      video,svg rect{
         mix-blend-mode: lighten;
         width:100%;
         height:100%;
         object-fit: contain;
         position: relative;
         z-index: 3;
         animation-direction: alternate;
         animation-duration: 10s;
         animation-iteration-count: infinite;
         animation-name: huecolors;
         animation-timing-function: linear;
      }

      svg{
         width:100%;
         height:100%;
         position: relative;
         z-index: 99;
      }

      .tokenomicsRoboImg{
         -webkit-animation-name: huecolors;
         animation-name: huecolors;
         -webkit-animation-duration: 10s;
         animation-duration: 10s;
         -webkit-animation-timing-function: linear;
         animation-timing-function: linear;
         -webkit-animation-iteration-count: infinite;
         animation-iteration-count: infinite;
         -webkit-animation-direction: alternate;
         animation-direction: alternate;
         height:100%;
      }

      @media(max-width:991px){
         width:100%;
         height:400px;

         @media(max-width:767px){
            height:auto;
         }

         .tokenomicsRoboImg{
            position: absolute;
            left: 0px;
            top:0px;
            width:100%;
            height:100%;
            object-fit: cover;
            object-position: top left;

            @media(max-width:767px){
               display:none;
            }
         }

      }
      
      .tokenomicsBanner{
         display:none;

         @media(max-width:767px){
            display:block;
         }         
      }
   }

   .tokenomicsRoboCoin{
      position: absolute;
      left: 0px;
      top: 60px;
      height: 160px;

      @media(min-width:1200px){
         left: 60px;
         top: 55px;
      }
      @media(max-width:991px){
         left: 70px;
         top: 38px;
      }
      @media(max-width:767px){
         display:none;
      }
   }
}
.tknmcsBottomCnt{
   background:url('../../../images/Home/Tokenomics/tokenomicsshadowBg.png') no-repeat left center;
   background-size:contain;
   padding-top:60px;

   .tknmcsGraphCnt{
      position: relative;
      display: inline-flex;
      justify-content: center;
      align-items: center;

      .coinImg{
         position: absolute;
         top:calc(50%);
         left:50%;
         transform: translate(-50% , -50%);

         @media(max-width:767px){
            max-width:calc(100% - 125px);
         }
      }

      .tokenBanner{
         position: relative;

         .tokenShadow{
            position: absolute;
            bottom:-48px;
            left:50%;
            transform: translateX(-50%);
            max-width:100%;
         }
      }

      
   }
   .supplyCnt{
      h3{
         &.heading{
            font-size: clamp(16px, 2.17vw, 26px);
            font-weight: 300;
            @media(min-width: 992px) and (max-width:1300px){
               font-size: 24px; 
            }

            span{
               color:#FFE388;
               font-weight:700;
               margin-left:15px;
            }
         }
      }

      .borderedCnt{
         padding:25px;
         @media(max-width: 1440px){
            padding: 18px;
         }
      }
   }
   .supplyRow{
      // font-size: clamp(16px, 1.33vw, 16px);
      font-size: 16px;
      @media(max-width: 1440px){
         font-size: 15px;
      }
      position: relative;
      margin-bottom:15px;
      z-index: 2;

      .grpLegends{
         width:34px;
         height:24px;
         border-radius: 2px;
      }

      span{
         margin-right: 5px;
         margin-bottom: 5px;

         &.typeCnt{
            width:160px;            
            @media(max-width: 1439px){
               width: 150px;
            }
         }
         &.percCnt{
            width:45px;

            @media(max-width:428px){
               text-align: right;
            }
         }
         &.valueCnt{
            width:160px;            
            @media(max-width: 1439px){
               width: 150px;
            }
            color:#F8DC8F;
            font-weight:500;
         }
      }
   }
}