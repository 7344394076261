.authPagesCnt{
   width:100%;
   min-height:90vh;
   border-radius:0px;
   border:none;
   overflow-y: auto;
   background:#09090B url('../../images/Auth/loginBg.png') no-repeat center bottom;
   display: flex;
   justify-content: center;
   align-items: center;

   h3{
      &.modalHeading{
         font-size:38px;
         font-weight:900;
         font-family: var(--font-druk);
         background: -webkit-linear-gradient(-85deg, rgba(71,71,71,1) 0%, rgba(255,255,255,1) 50%, rgba(158,158,158,1) 100%);
         -webkit-background-clip: text;
         -webkit-text-fill-color: transparent;
         text-align:center;
         padding-bottom:20px;
         margin-bottom: 15px;
         position:relative;

         &::before{
            content:'';
            width:168px;
            height: 1px;
            background:var(--white-06);
            position: absolute;
            left:50%;
            bottom:0px;
            transform: translateX(-50%);
         }
      }
   }
   p{
      &.modalSubHeading{
         font-size:16px;
         color:var(--white-06);
         text-align:center;
         font-family: "Sora", sans-serif;
         letter-spacing:0.05rem;
      }
   }
   .close{
      width:26px;
      background:url('../../images/Auth/modalClose.png') no-repeat center center;
      background-size:contain;
      span{
         display:none;
      }
   }
   /* Checkbox cnt */
   .checkCnt {
      display: block;
      position: relative;
      padding-left: 25px;
      margin: 4px 0px;
      cursor: pointer;
      font-size: 14px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
   }
   
   /* Hide the browser's default checkbox */
   .checkCnt input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
   }
   
   /* Create a custom checkbox */
   .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 18px;
      width: 18px;
      background-color: rgba(255,255,255,0.05);
      border:1px solid rgba(255,255,255,0.1);
      border-radius: 5px;
   }
   
   /* On mouse-over, add a grey background color */
   .checkCnt:hover input ~ .checkmark {
      background-color: #000000;
   }
   
   /* When the checkbox is checked, add a blue background */
   .checkCnt input:checked ~ .checkmark {
      background-color: #F8DC8F;
   }
   
   /* Create the checkmark/indicator (hidden when not checked) */
   .checkmark:after {
      content: "";
      position: absolute;
      display: none;
   }
   
   /* Show the checkmark when checked */
   .checkCnt input:checked ~ .checkmark:after {
      display: block;
   }
   
   /* Style the checkmark/indicator */
   .checkCnt .checkmark:after {
      left: 6px;
      top: 3px;
      width: 5px;
      height: 10px;
      border: solid #000000;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
   }
}