.siteLoaderCnt{
   position: fixed;
   width:100%;
   height:100vh;
   top: 0;
   left: 0;
   background:#0E0E10;
   display: flex;
   justify-content: center;
   align-items: center;
   padding:25px;
   z-index: 999;
   opacity: 1;
   transition: all 2s;

   .loaderInnerDiv{
    transform: scale(1);
    transition: all 2s;
   }

   @media(max-width:991px){
      display: none;
   }

   &.loaded{
      opacity: 0;
      visibility: hidden;

      .loaderInnerDiv{
        transform: scale(0);
      }
   }

   .spinner {
      position: relative;
      margin: auto;
      box-sizing: border-box;
      background-clip: padding-box;
      width: 200px;
      height: 200px;
      border-radius: 100px;
      border: 4px solid rgba(255, 255, 255, 0.1);
      -webkit-mask: linear-gradient(rgba(0, 0, 0, 0.1), #000000 90%);
      transform-origin: 50% 60%;
      transform: perspective(200px) rotateX(66deg);
      animation: spinner-wiggle 1.2s infinite;
    }
    .spinner:before,
    .spinner:after {
      content: "";
      position: absolute;
      margin: -4px;
      box-sizing: inherit;
      width: inherit;
      height: inherit;
      border-radius: inherit;
      opacity: .05;
      border: inherit;
      border-color: transparent;
      animation: spinner-spin 1.2s cubic-bezier(0.6, 0.2, 0, 0.8) infinite, spinner-fade 1.2s linear infinite;
    }
    
    .spinner:before {
      border-top-color: #66e6ff;
    }
    
    .spinner:after {
      border-top-color: #f0db75;
      animation-delay: 0.3s;
    }
    
    @keyframes spinner-spin {
      100% {
        transform: rotate(360deg);
      }
    }
    @keyframes spinner-fade {
      20% {
        opacity: .1;
      }
      40% {
        opacity: 1;
      }
      60% {
        opacity: .1;
      }
   }

   /* Loader circles */
   .loaderCoin{
      transform: translate(0px, 152px);

      img{
         transform:translateY(0px);
         -webkit-animation: bounce .4s ease infinite alternate;
         animation: bounce .4s ease infinite alternate;
      }
      
   }
}

@keyframes bounce {
   0% {
      transform:translateY(0px); 
   }
   100% {
     transform:translateY(-20px);
   }
}
@-webkit-keyframes bounce {
   0% {
      transform:translateY(0px); 
   }
   100% {
     transform:translateY(-20px);
   }
}

