.animated_div {
    animation: fadeIn .3s ease-in;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

/* width */
::-webkit-scrollbar {
    width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #555;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: linear-gradient(97.53deg, #C5A252 6.3%, #F8DC8F 53.15%, #C5A252 100%);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #C5A252;
}

/* loader */
@mixin oct-load {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: clamp(3rem, 3vw, 3rem);
    backdrop-filter: blur(2px);
    z-index: 9999;
}

@mixin oct-div {
    width: 1em;
    height: 1em;
    border-radius: 50%;
    box-shadow: 0.05em 0.05em 0 0 #C5A252;
    transform-origin: 0.5em;
    animation: vp-loader 1s linear infinite;
}

@keyframes vp-loader {
    0% {
        transform: rotate(0);
    }

    100% {
        transform: rotate(360deg);
    }
}

.viewMre {
    font-size: 14px;
    line-height: 26px;
    color: #FFE388;
    text-decoration: underline;
}

.errMsg {
    font-size: 14px !important;
    color: red;
}

.errMsgKyc {
    font-size: 12px !important;
    color: red;
}

.btnGold {
    transition: 0.3s ease-in;
}

.page-loader {
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    z-index: 9999;
    @include oct-load();

    div {
        @include oct-div();
    }
}

.form-control {
    box-shadow: none !important;
    font-size: 14px;

    &:disabled,
    &:read-only {
        background: rgba(255, 255, 255, 0.05);
    }
}

.agrTrm a {
    line-height: unset;
}

.react-date-picker {
    width: 100%;
    height: 45px;
    background: transparent;
    color: #ffffff;
    font-weight: 600;

    .react-date-picker__wrapper {
        border: 1px solid rgba(255, 255, 255, 0.1);
        border-radius: 5px;
    }

    input {
        border: none;
        background: transparent !important;

        &:focus-visible {
            background: transparent;
            outline: none;
        }
    }

    button {
        color: #fff;
    }

    .react-calendar__tile--now {
        background: none;
    }

    .react-calendar__tile--active {
        background: linear-gradient(97.53deg, #C5A252 6.3%, #F8DC8F 53.15%, #C5A252 100%);
        color: #212529;

        &:hover {
            background: linear-gradient(97.53deg, #C5A252 6.3%, #F8DC8F 53.15%, #C5A252 100%) !important;
        }
    }

    .react-calendar {
        margin-top: 10px;
        background: #212529;
        border: 1px solid rgba(255, 255, 255, 0.1);
        border-radius: 5px;
    }

    .react-calendar__tile:enabled:hover,
    .react-calendar__tile:enabled:focus,
    .react-calendar__navigation button:enabled:hover,
    .react-calendar__navigation button:enabled:focus {
        background: none;
    }

    .react-calendar__navigation button:disabled,
    .react-calendar__tile:disabled {
        background: none;
        opacity: 0.5;
    }
}

.noItemFound {
    text-align: center;
    margin: 34px 15px;

    .noItemfoundImage {
        width: 100px;
    }

    .nofounttxt {
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        color: var(--dark-text, #F6F6F6);
    }
}

.grnTxt {
    color: #05af05 !important;
}

.go2072408551 {
    background: none !important;
    padding: 0 !important;
}

.alertToast {
    min-width: 250px;
    padding: 12px;
    border-radius: 12px;
    box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.76);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    background: #323740;
    color: #fff;

    .closeToastBtn {
        background: transparent;
        border: none;
    }

    .d-flex {
        gap: 12px;
    }

}

.truncate {
    display: flex;
    align-items: center;
    word-wrap: normal;

    p {
        margin: 0;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .CVp {
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 180px;
    }
}

.prf-uppBtn {
    position: relative;
    overflow: hidden;
    display: inline-block;

    input[type=file] {
        font-size: 100px;
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;
    }
}



select,
textarea {
    width: 100%;
    background: transparent;
    border: 1px solid rgba(255, 255, 255, 0.1);
    text-align: left;
    padding: 0.6rem 0.75rem;
    color: #F6F6F6;

}


.stkChart {
    .input-group-text {
        position: absolute;
        right: 0;
        bottom: 0;
        top: 0;
        color: #fff;
    }
}

.octDrpdown.dark {
    button {
        background: #0C0C0C !important;
        border-color: #474747;
    }
}

.octDrpdown .dropdown {
    .btn {
        width: 100%;
        background: rgba(255, 255, 255, 0.05);
        background: rgba(255, 255, 255, 0.05) url('assets/images/ddIcon.png') no-repeat center right 10px;
        border: 1px solid rgba(255, 255, 255, 0.1);
        text-align: left;
        padding: .6rem .75rem;

        &:focus,
        &:focus-visible {
            box-shadow: none;
        }
    }

    .dropdown-menu {
        background: #212529;
        padding: 0;
        width: 100%;
        max-height: 350px;
        overflow: hidden;
        overflow-y: auto;

        .dropdown-item {
            color: #F6F6F6;
            text-align: left;

            &:hover {
                background: linear-gradient(97.53deg, #C5A252 6.3%, #F8DC8F 53.15%, #C5A252 100%);
                color: #212529;
            }
        }
    }
}

.nji_ki {
    position: relative;

    input {
        width: 100% !important;
        height: 45px !important;
        background: rgba(255, 255, 255, 0.05) !important;
        border: 1px solid rgba(255, 255, 255, 0.1) !important;
        border-radius: 5px !important;
        color: #ffffff !important;
    }

    .flag-dropdown.phnDrpBtn,
    .flag-dropdown.phnDrpBtn:focus,
    .react-tel-input {

        .flag-dropdown.open .selected-flag,
        .flag-dropdown {
            background: rgba(255, 255, 255, 0.05) !important;
            border: 0px;
            border-right: 1px solid rgba(255, 255, 255, 0.1);
        }

        .country-list {
            .country.highlight {
                background: linear-gradient(97.53deg, #C5A252 6.3%, #F8DC8F 53.15%, #C5A252 100%) !important;
                color: #000;
            }

            .country:hover {
                background: #323740 !important;
            }

            li {
                background-color: #212529;
            }
        }
    }

    .nji_kiTxt {
        position: absolute;
        right: 2%;
        top: 28%;
    }
}

.ReactFlagsSelect-module_filterBox__3m8EU {
    border: none !important;
    background: #212529 !important;
}

.ReactFlagsSelect-module_selectOptions__3LNBJ {
    background: #212529 !important;
    max-height: 250px !important;
    border: 1px solid rgba(255, 255, 255, 0.1) !important;
    border-radius: 5px;

    input {
        background: rgba(255, 255, 255, 0.05);
        border: 1px solid rgba(255, 255, 255, 0.1) !important;
        border-radius: 5px;
        color: #fff;
    }
}

.ReactFlagsSelect-module_selectOption__3pcgW:hover {
    background: linear-gradient(97.53deg, #C5A252 6.3%, #F8DC8F 53.15%, #C5A252 100%) !important;
    color: #000;
}

.ReactFlagsSelect-module_selectBtn__19wW7 {
    background: rgba(255, 255, 255, 0.05) !important;
    color: #fff !important;
}

input[type=file] {
    cursor: pointer;
}

button:disabled,
input:disabled,
select:disabled {
    cursor: not-allowed;
}


.profileMail {
    word-wrap: normal;
    max-width: 180px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.alert-dismissible {
    button {
        display: none;
    }
}

.alert-success {
    background: #24674f !important;
}

form {
    .react-date-picker__inputGroup {
        padding: .375rem .75rem;
    }

    .react-date-picker__inputGroup__input,
    .react-date-picker__inputGroup__leadingZero {
        font-size: 14px;
        font-weight: 400;
    }

    .react-calendar__tile--hasActive {
        background: linear-gradient(97.53deg, #C5A252 6.3%, #F8DC8F 53.15%, #C5A252 100%) !important;
    }

    .input-group {
        .input-group-text {
            .dropdown {
                .btn {
                    padding: 0px;
                    background: transparent;
                    border: none;

                    &:focus {
                        box-shadow: none;
                    }
                }

                .dropdown-menu {
                    top: 10px !important;
                    background: rgb(71, 71, 71);
                    background: linear-gradient(-88deg, rgba(71, 71, 71, 1) 0%, rgba(15, 14, 24, 1) 51%, rgba(71, 71, 71, 1) 100%);
                    border-radius: 10px;
                    position: relative;

                    &::before {
                        content: '';
                        position: absolute;
                        left: 1px;
                        top: 1px;
                        width: calc(100% - 2px);
                        height: calc(100% - 2px);
                        z-index: 1;
                        background-color: #0C0C0C;
                        border-radius: 10px;
                    }

                    .dropdown-item {
                        color: #ffffff;
                        padding: 8px 10px;
                        font-size: 14px;
                        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
                        position: relative;
                        z-index: 3;

                        &:last-child {
                            border-bottom: 0px;
                        }

                        &:hover {
                            background: linear-gradient(97.53deg, #C5A252 6.3%, #F8DC8F 53.15%, #C5A252 100%) !important;
                            color: #000;
                        }
                    }
                }
            }

        }
    }

    .form-control {
        &:focus {
            border-color: rgba(255, 255, 255, 0.1);
        }
    }
}


input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
    /* Firefox */
}

.innerMrg {
    margin: 1rem;
}

.cardInnerCnt {
    ::-webkit-scrollbar {
        width: 2px;
    }
}

.stkStrView {

    input,
    .input-group-text {
        border: none !important;
    }
}

.scdryTxt {
    opacity: .6;
    font-size: 14px;
}

button:focus,
button:focus-visible {
    box-shadow: none !important;
}

.qrLoader {
    width: 240px;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.alert {
    border-radius: 8px;
    padding: 2px 0px;
    width: 100%;
    max-width: 760px;
    margin: 20px auto;

    &.alert-danger {
        background: rgba(241, 71, 87, 0.4);
        border-color: #AB4E58;
    }

    h3 {
        font-size: 16px;
        color: #ffffff;
        line-height: 24px;
        margin-bottom: 0px;
    }

    p {
        font-size: 14px;
        font-weight: 300;
        color: #ffffff;
        line-height: 24px;
        margin-bottom: 0px;
    }

    .close {
        color: #ffffff;
    }
}


.err_pg {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .secHeading {
        font-size: clamp(45px, 2.5vw, 30px) !important;
    }

    .err_img {
        width: 70%;
        margin: 3rem auto;
    }
}

.urNme{
    width: 120px;
    span{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}


canvas {
    border: 2px solid rgb(248, 246, 246);
    border-radius: 20px;
}

.refTstCnt{
    height: auto;
    background: rgba(255, 255, 255, 0.05);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 5px;
    color: #ffffff;
    padding:10px 15px;
}